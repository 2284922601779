import React from 'react';

import SEO from '../components/seo';

const Share = (props) => {

  return (
    <>
      <SEO title="Faça sua parte também" />

      <div className="share-container" style={{ backgroundImage: `url(${require('../images/backgrounds/section-dots-bg.svg')})` }}>
        <a href="https://malwee.atitudesdobem.com/" className="share-link">
          <img src={require('../images/pics/selo_Malwee_AtitudesdoBem.jpg')} alt="Imagem de compartilhamento" className="share-image"/>
        </a>
      </div>
    </>
  );

};

export default Share;
